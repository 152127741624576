import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { ThemeProvider } from 'styled-components';
import { GlobalStyle } from 'styles/global-styles';
import theme from 'styles/theme';
import Footer from 'atomic-design/molecules/footer';
import Navbar from 'atomic-design/molecules/navigation/navbar/index';
import MobileMenu from 'atomic-design/molecules/navigation/mobile-menu/index';
import ScrollToTop from 'atomic-design/atoms/scroll-to-top';
import ReadingProgress from 'atomic-design/atoms/reading-progress';
import useIsMobile from 'hooks/useIsMobile';
import { Amplify } from 'aws-amplify';
import awsconfig from '../../../aws-exports';
import Container, { MainContainer } from './styles';
import { useNavList } from 'hooks/useSiteMetadata';

Amplify.configure(awsconfig);

const Layout = ({ children, path, isHome, withReadingProgress, showMenu, whiteNaNLogo }) => {
  const [open, setOpen] = useState(false);
  const isMobile = useIsMobile('mobile');
  const navList = useNavList();

  const ref = useRef(null);
  const handleToggle = () => setOpen(!open);

  useEffect(() => {
    if (!isMobile) {
      setOpen(false);
    }
  }, [isMobile]);

  return (
    <ThemeProvider theme={theme}>
      <Container isHome={isHome}>
        <GlobalStyle />
        <Navbar
          navList={navList}
          path={path}
          isHome={isHome}
          handleToggle={handleToggle}
          isMenuOpen={open}
          showMenu={showMenu}
          whiteNaNLogo={whiteNaNLogo}
        />
        {withReadingProgress && <ReadingProgress target={ref} />}
        <MobileMenu navList={navList} path={path} isHome={isHome} isOpen={open} />
        <MainContainer ref={ref}>{children}</MainContainer>

        <Footer navList={navList} />
        <ScrollToTop showBelow={250} />
      </Container>
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  isHome: PropTypes.bool,
  showMenu: PropTypes.bool,
  whiteNaNLogo: PropTypes.bool,
};

Layout.defaultProps = {
  isHome: false,
  showMenu: true,
  whiteNaNLogo: false,
};

export default Layout;
