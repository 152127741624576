import styled from 'styled-components';

export const List = styled.ul`
  list-style: none;
  text-decoration: none;
  margin: 0;
  padding: 0;

  li:last-child {
    margin: 0;
  }
`;

export const ListItem = styled.li`
  margin-right: ${({ theme }) => theme.spacing.medium};
  margin-bottom: ${({ theme }) => theme.spacing.medium};
  transition: ${({ theme }) => theme.transition};
  display: inline-block;

  &:hover {
    opacity: ${({ theme }) => theme.opacity.secondary};
    transition: ${({ theme }) => theme.transition};
  }

  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    &:hover {
      opacity: 1;
    }
  }
`;
