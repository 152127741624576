import styled from 'styled-components';

export const IconContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: ${({ theme }) => theme.spacing.medium};
  border: none;
  background-color: transparent;
  transform: translateX(8px);
`;

export const IconLine = styled.div`
  position: absolute;
  width: ${({ theme }) => theme.spacing.medium};
  height: 2px;
  background-color: ${({ theme }) => theme.fonts.colors.secondary};
  transform: rotate(0deg);
  transition: ${({ theme }) => theme.transition};
  ${({ openClose }) => (openClose ? 'transform: rotate(90deg)' : 'transform: rotate(0)')};

  @media only screen and (${({ theme }) => theme.breakpoints.tablet}) {
    width: ${({ theme }) => theme.spacing.smallXL};
  }

  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    width: ${({ theme }) => theme.spacing.smallXL};
  }
`;
