import React from 'react';

import Heading3 from 'atomic-design/organisms/rich-text/heading3';
import Heading2 from 'atomic-design/organisms/rich-text/heading2';
import Blockquote from 'atomic-design/atoms/blockquote';

export const capitalizeFirstLetter = string => {
  const lowerCase = string.toLowerCase();
  return lowerCase.charAt(0).toUpperCase() + lowerCase.slice(1);
};

export const handleHeading2 = node => {
  if (node.content && node.content && node.content[0].value) {
    const text = capitalizeFirstLetter(node.content[0].value);
    return <Heading2 text={text} />;
  }
};

export const handleHeading3 = node => {
  if (node.content && node.content && node.content[0].value) {
    const text = capitalizeFirstLetter(node.content[0].value);
    return <Heading3 text={text} />;
  }
};

export const newHandleQuote = node => {
  let textQuote = '';
  let url;
  let authorQuote;
  if (node.content && node.content[0].content[0] && node.content[0].content[0].value) {
    node.content[0].content.map(content => {
      if (content.nodeType === 'text') {
        textQuote = textQuote.concat(content.value);
      } else if (content.nodeType === 'hyperlink') {
        url = content.data.uri;
        if (content.content[0].nodeType === 'text' && content.content[0].value !== '') {
          textQuote = textQuote.concat(content.content[0].value);
        }
      }
    });
  }

  if (node.content.length > 1 && node.content[1].content.length > 1) {
    node.content[1].content.forEach(node => {
      if (node.nodeType === 'hyperlink') {
        url = node.data.uri;
        if (node.content[0].nodeType === 'text' && node.content[0].value !== '') {
          authorQuote = node.content[0].value;
        }
      }
    });
  }

  return <Blockquote text={textQuote && textQuote} author={authorQuote} link={url} />;
};

export const handleQuote = node => {
  let textQuote;
  let url;
  let authorQuote;
  if (node.content && node.content[0].content[0] && node.content[0].content[0].value) {
    textQuote = node.content[0].content[0].value;
    if (node.content[0].content[1] && node.content[0].content[1].value) {
      authorQuote = node.content[0].content[1].value;
    }
  }
  if (node.content.length > 1 && node.content[1].content.length > 1) {
    node.content[1].content.forEach(node => {
      if (node.nodeType === 'hyperlink') {
        url = node.data.uri;
        if (node.content[0].nodeType === 'text' && node.content[0].value !== '') {
          authorQuote = node.content[0].value;
        }
      }
    });
  } else {
    if (node.content.length > 1) {
      authorQuote = node.content[1].content[0].value;
    }
  }

  return <Blockquote text={textQuote} author={authorQuote} link={url} />;
};

export const trimEllipsis = (string, value) => {
  return string.length > value ? string.substring(0, value) + '...' : string;
};

export const replaceText = (text, index, replacement) => text.replace(index, replacement);

export const renderLineBreak = text => {
  return text.split('\n').reduce((children, textSegment, index) => {
    return [...children, index > 0 && <br />, textSegment];
  }, []);
};

export const convertToSlug = text => {
  return text
    .toString() // Cast to string
    .toLowerCase() // Convert the string to lowercase letters
    .normalize('NFD') // The normalize() method returns the Unicode Normalization Form of a given string.
    .trim() // Remove whitespace from both sides of a string
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w\-]+/g, '') // Remove all non-word chars
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // trim - from start of text
    .replace(/-+$/, ''); // trim - from end of text
};

export const injectId = node => {
  if (node.nodeType.includes('heading')) {
    const slug = convertToSlug(node.content[0].value);
    switch (node.nodeType) {
      case 'heading-1':
        return <h1 id={slug}>{node.content[0].value}</h1>;
      case 'heading-2':
        return <h2 id={slug}>{node.content[0].value}</h2>;
      case 'heading-3':
        return <h3 id={slug}>{node.content[0].value}</h3>;
      case 'heading-4':
        return <h4 id={slug}>{node.content[0].value}</h4>;
      case 'heading-5':
        return <h5 id={slug}>{node.content[0].value}</h5>;
      case 'heading-6':
        return <h6 id={slug}>{node.content[0].value}</h6>;
      default:
        break;
    }
  }
  return node;
};
