import styled from 'styled-components';

export const Container = styled.nav`
  @media only screen and (${({ theme }) => theme.breakpoints.tablet}) {
    margin: 5vh 0;
    height: 90vh;
    ul {
      padding-left: 0;

      li:hover {
        button {
          color: ${({ theme }) => theme.colors.primary};
        }
      }
    }
  }
`;

export const NavListStyled = styled.ul`
  display: flex;
  align-items: center;
  list-style-type: none;
  flex-grow: 5;
  margin: 0;
  li {
    margin-right: ${({ theme }) => theme.spacing.longSM};
    &:last-child {
      margin-right: 0;
    }

    a {
      &:hover {
        color: ${({ theme, home }) => (home ? theme.colors.lightGrey : theme.colors.primary)};
      }
    }
  }

  @media only screen and (${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: column;
    & li:last-child {
      a {
        display: flex;
        justify-content: flex-end;
      }
      &:after {
        display: none;
      }
    }
  }

  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    padding: 0;
  }
`;
