import styled from 'styled-components';

export const Menu = styled.div`
  width: 100%;
  height: 100vh;
  background: ${({ theme }) => theme.colors.white};
  position: fixed;
  z-index: 99;
  top: 0;
  padding: ${({ theme }) => theme.spacing.large};
  transition: ${({ theme }) => theme.transition};
  animation: ${({ fade }) => (fade ? 'fadeIn .1s ease-in' : 'fadeOut .1s ease-out')};
  display: ${({ display }) => (display ? 'block' : 'none')};

  nav {
    width: 100%;
  }

  ul {
    display: flex;
    align-items: flex-start;
  }

  li {
    padding: 0;
    margin: 0;
    padding: ${({ theme }) => theme.spacing.medium} 0;
    width: 100%;

    a {
      width: 100%;
      display: block;

      @media only screen and (${({ theme }) => theme.breakpoints.tablet}) {
        color: ${({ theme }) => theme.colors.grey} !important;

        span {
          color: ${({ theme }) => theme.colors.grey} !important;
        }
      }
    }

    a {
      color: ${({ theme }) => theme.colors.grey};
      font-size: ${({ theme }) => theme.fonts.sizes.headings.heading6};
      border: none;

      &:hover,
      &:active {
        background: transparent;
        border: none;
      }
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  height: 100%;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  overflow-y: scroll;

  @media only screen and (${({ theme }) => theme.breakpoints.tablet}) {
    overflow-y: scroll;
    margin: ${({ theme }) => theme.spacing.spacing40} 0;
  }
`;

export const MenuTitle = styled.h4`
  font-weight: 500;
  font-size: ${({ theme }) => theme.fonts.sizes.headings.heading3};
  color: ${({ theme }) => theme.colors.grey};
  padding-top: 0;
  padding-bottom: ${({ theme }) => theme.spacing.small};
  margin: 0;
`;

export const BtnCloseContainer = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  margin: ${({ theme }) => theme.spacing.small};

  @media only screen and (${({ theme }) => theme.breakpoints.tablet}) {
    padding: 0;
  }
`;
