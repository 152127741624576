import React, { useEffect, useState } from 'react';

import theme from 'styles/theme';
import { LeftArrow } from 'ui/svg';
import ScrollButton from './styles';

const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  const toggleVisibility = () => {
    if (window.pageYOffset > 700) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  return (
    <>
      {isVisible && (
        <ScrollButton onClick={scrollToTop} aria-label="scroll to top" isShowing={isVisible}>
          <LeftArrow fill={theme.newTheme.color.primary.main} />
        </ScrollButton>
      )}
    </>
  );
};

export default ScrollToTop;
