import styled from 'styled-components';

export const OfficeContainer = styled.div`
  padding-bottom: 34px;
`;

export const Address = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  text-align: right;
  letter-spacing: -0.01em;
  color: #bdbdbd;
  margin: 0;
  padding: 0;

  @media only screen and (${props => props.theme.breakpoints.mobile}) {
    text-align: left;
  }
`;

export const Country = styled(Address)`
  font-weight: 600;
  line-height: 30px;
`;
