import React from 'react';
import PropTypes from 'prop-types';

import { List, ListItem } from './styles';

const SocialNetworkList = ({ iconList }) => {
  return (
    <List>
      {iconList.map(network => (
        <ListItem key={network.name}>
          <a aria-label={network.name} href={network.to} rel="noreferrer noopener" target="_blank">
            <span alt={network.name}>{network.icon}</span>
          </a>
        </ListItem>
      ))}
    </List>
  );
};

SocialNetworkList.propTypes = {
  iconList: PropTypes.array.isRequired,
};

export default SocialNetworkList;
