import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from 'atomic-design/atoms/button';
import Dropdown from 'atomic-design/molecules/dropdown';
import AccordionButtonIcon from 'atomic-design/molecules/accordion/accordion-button-icon';
import useIsMobile from 'hooks/useIsMobile';
import {
  Item,
  LinkItem,
  Action,
  DropdownNavItem,
  MobileAccordion,
  AccordionSection,
  AccordionSectionTitle,
} from './styles';
import { ChevronDownIcon } from 'ui/svg';

const NavItem = ({
  to,
  label,
  isActive,
  isFixed,
  isHome,
  isDropdown,
  dropdownList,
  isButton,
  handleSelectView,
  show,
}) => {
  const isMobile = useIsMobile('tablet');

  const setLabel = () => {
    if (isDropdown) {
      return (
        <DropdownNavItem active={isActive}>
          {label}
          {!isMobile && <ChevronDownIcon size={20} />}
        </DropdownNavItem>
      );
    }
    if (isButton) {
      return <Button>{label}</Button>;
    }

    return label;
  };

  const handleClickItem = () => {
    handleSelectView(show ? null : label);
  };

  return (
    <>
      <Item
        isActive={isActive}
        isHome={isHome}
        isFixed={isFixed}
        {...(!isMobile && { onMouseEnter: () => handleSelectView(isDropdown ? label : null) })}
      >
        <LinkItem to={to} label={label} {...(isMobile && { onClick: handleClickItem })}>
          {setLabel()}
        </LinkItem>

        {isMobile && isDropdown && (
          <Action>
            <Button variant="icon" ariaLabel={show ? 'open' : 'close'} onClick={handleClickItem}>
              <AccordionButtonIcon openClose={!show} />
            </Button>
          </Action>
        )}
      </Item>
      {isMobile && isDropdown && !!dropdownList?.length && (
        <MobileAccordion show={show}>
          {dropdownList?.map(item => (
            <AccordionSection>
              {item.label ? (
                <>
                  <AccordionSectionTitle>{item.label}</AccordionSectionTitle>
                  {item.links.map(link => (
                    <LinkItem to={link.to} label={link.label} light>
                      {link.label}
                    </LinkItem>
                  ))}
                </>
              ) : (
                <>
                  {item.links.map(link => (
                    <LinkItem to={link.to}>
                      <AccordionSectionTitle>{link.label}</AccordionSectionTitle>
                    </LinkItem>
                  ))}
                </>
              )}
            </AccordionSection>
          ))}
        </MobileAccordion>
      )}
    </>
  );
};

NavItem.propTypes = {
  to: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isHome: PropTypes.bool.isRequired,
  isFixed: PropTypes.bool.isRequired,
  isActive: PropTypes.bool.isRequired,
  handleSelectView: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

export default NavItem;
