import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { normalizePath } from 'common/normalizePath';

const isSSR = typeof window === 'undefined';

const getHostUrl = () =>
  new URL(isSSR ? process.env.GATSBY_SITE_URL : `http://${window.location.host}`);

const isOurSite = to => {
  if (!to) return false;
  const toUrl = new URL(to);

  return getHostUrl().host === toUrl.host;
};

const isInternal = to => to && /^\/(?!\/)/.test(to);

const Link = ({ to, children, ...rest }) => {
  if (to === '#') {
    return (
      <a href="#" {...rest}>
        {children}
      </a>
    );
  }

  if (to?.startsWith('mailto:')) {
    return (
      <a href={to} rel="noreferrer noopener" {...rest}>
        {children}
      </a>
    );
  }

  if (isInternal(to)) {
    return (
      <GatsbyLink to={normalizePath(to)} {...rest}>
        {children}
      </GatsbyLink>
    );
  }

  if (isOurSite(to)) {
    const url = new URL(to);
    return (
      <GatsbyLink to={normalizePath(url.pathname)} {...rest}>
        {children}
      </GatsbyLink>
    );
  }

  return (
    <a href={normalizePath(to)} rel="noopener noreferrer" {...rest}>
      {children}
    </a>
  );
};

export default Link;
