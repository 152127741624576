import styled from 'styled-components';
import Link from 'atomic-design/atoms/link';

export const LinkItem = styled(Link)`
  font-style: normal;
  font-weight: 500;
  font-size: ${({ theme }) => theme.fonts.sizes.primary};
  text-decoration: none;
  @media only screen and (${({ theme }) => theme.breakpoints.tablet}) {
    ${({ light }) => light && 'color: var(--text-disabled, rgba(0, 0, 0, 0.38));'}
    padding: ${({ theme }) => theme.spacing.smallest} 0;
  }
`;

export const LinkDisable = styled.a`
  font-style: normal;
  font-weight: 500;
  font-size: ${({ theme }) => theme.fonts.sizes.primary};
  text-decoration: none;
`;

export const Item = styled.li`
  a,
  a span, a span svg path {
    color: ${({ isActive, isFixed, isHome, theme }) => {
      if (isActive) return theme.colors.primary;
      return isHome ? (isFixed ? theme.colors.grey : theme.colors.white) : theme.colors.grey;
    }} !important;

    &: hover {
      color: ${({ theme, isHome, isFixed }) =>
        isHome
          ? isFixed
            ? theme.colors.primary
            : theme.colors.lightGrey
          : theme.colors.primary} !important;
    }
  }

  @media only screen and (${({ theme }) => theme.breakpoints.tablet}) {
    a {
      font-size: ${({ theme }) => theme.fonts.sizes.heading25} !important;
    }
    a span {
      color: ${({ isActive, theme }) => (isActive ? theme.colors.primary : theme.colors.grey)};
    }

    position: relative;
    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      border: 1px solid ${({ theme }) => theme.colors.divider};
    }

    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  }
`;

export const Action = styled.div`
  @media only screen and (${({ theme }) => theme.breakpoints.tablet}) {
    display: flex;
    div {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
`;

export const DropdownNavItem = styled.span`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.smallest};
  svg path {
    transform-origin: center;
    ${({ active }) => active && 'transform: rotate(180deg) !important;'}
    transition: ${({ theme }) => theme.transition};
  }
`;

export const MobileAccordion = styled.div`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  height: ${({ show }) => (show ? 'auto' : '0')};
  flex-direction: column;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing.smallXL} 0;
  transition: ${({ theme }) => theme.transition};
`;

export const AccordionSection = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AccordionSectionTitle = styled.p`
  color: ${({ theme }) => theme.colors.primaryDarkText};
  font-size: ${({ theme }) => theme.fonts.sizes.primary};
  margin: ${({ theme }) => theme.spacing.smallest} 0;
`;
