import React from 'react';
import PropTypes from 'prop-types';

import {
  Container,
  ContainerQuote,
  TextContainer,
  FullPlane,
  Quote,
  QuoteText,
  QuoteAuthor,
  Link,
  QuoteDiv,
} from './styles';

const Blockquote = ({ text, author, link }) => {
  return (
    <Container>
      <ContainerQuote>
        <FullPlane />
        <QuoteDiv>
          <Quote>"</Quote>
        </QuoteDiv>
        <FullPlane />
      </ContainerQuote>
      <TextContainer>
        <QuoteText>{text}</QuoteText>
        {link && author ? (
          <Link href={link} target="_blank" rel="noreferrer">
            <QuoteAuthor>{author}</QuoteAuthor>
          </Link>
        ) : author ? (
          <QuoteAuthor>{author}</QuoteAuthor>
        ) : null}
      </TextContainer>
    </Container>
  );
};

Blockquote.propTypes = {
  text: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  link: PropTypes.string,
};

Blockquote.defaultProps = {
  link: '',
};

export default Blockquote;
