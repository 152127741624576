import styled from 'styled-components';

export const Heading2Styled = styled.h2`
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 60px;
  letter-spacing: -0.01em;
  color: ${({ theme }) => theme.colors.primary};
  margin: 0;
  padding: 0 0 26px 0;
`;

export const Heading3Styled = styled.h3`
  margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 39px;
  letter-spacing: -0.01em;
  color: ${({ theme }) => theme.colors.primary};
  padding: 18px 0 0 0;
`;
