import styled, { css } from 'styled-components';

const colorStyles = (theme, color) =>
  ({
    primary: css`
      color: ${theme.newTheme.color.primary.main};
      background: ${theme.newTheme.color.gradient.background.main};
      border: 1px solid ${theme.newTheme.color.primary.main};
    `,
    secondary: css`
      color: ${theme.newTheme.color.secondary.main};
      background-color: ${theme.newTheme.color.secondary.main};
      border: 1px solid ${theme.newTheme.color.secondary.main};
    `,
  }[color]);

const variantStyles = (theme, variant) =>
  ({
    contained: css`
      color: ${theme.newTheme.color.white};
      background: ${theme.newTheme.color.gradient.background.primary.main};
      border: none;
      &:hover {
        background: ${theme.newTheme.color.gradient.background.primary.hover};
      }
      &:active {
        background: ${theme.newTheme.color.gradient.background.primary.active};
      }
      &:disabled {
        border: 1px solid ${theme.newTheme.color.grey.light};
        background-color: ${theme.newTheme.color.grey.light};
        color: ${theme.newTheme.color.background.disabled};
      }

      @media only screen and (${theme.newTheme.breakpoints.tablet}) {
        &:hover,
        &:active {
          background: ${theme.newTheme.color.gradient.background.primary.main};
        }
      }

      @media only screen and (${theme.breakpoints.mobile}) {
        &:hover,
        &:active {
          background: ${theme.newTheme.color.gradient.background.primary.main};
        }
      }
    `,
    outlined: css`
      background: transparent;
      border: 1px solid ${theme.newTheme.color.primary.main} !important;
      &:hover {
        background-color: ${({ theme }) => ` ${theme.newTheme.color.primary.light}50`};
      }
      &:disabled {
        border: 1px solid ${theme.newTheme.color.background.disabled};
        background-color: transparent;
        color: ${theme.newTheme.color.background.disabled};
      }

      @media only screen and (${theme.newTheme.breakpoints.tablet}) {
        &:hover,
        &:active {
          border: 1px solid ${theme.newTheme.color.primary.main};
          background-color: transparent;
          color: ${theme.newTheme.color.primary.main};
        }
      }

      @media only screen and (${theme.newTheme.breakpoints.mobile}) {
        &:hover,
        &:active {
          border: 1px solid ${theme.newTheme.color.primary.main};
          background-color: transparent;
          color: ${theme.newTheme.color.primary.main};
        }
      }
    `,
    text: css`
      background: transparent !important;
      border: 1px solid transparent;
      &:hover {
        background-color: transparent;
        text-decoration: underline;
      }
      &:active {
        color: ${theme.newTheme.color.primary.light};
      }
      &:disabled {
        color: ${theme.newTheme.color.background.disabled};
        text-decoration: none;
      }

      @media only screen and (${theme.newTheme.breakpoints.tablet}) {
        &:hover,
        &:active {
          color: ${theme.newTheme.color.primary.main};
          text-decoration: none;
        }
      }

      @media only screen and (${theme.newTheme.breakpoints.mobile}) {
        &:hover,
        &:active {
          color: ${theme.newTheme.color.primary.main};
          text-decoration: none;
        }
      }
    `,
    icon: css`
      background: transparent;
      border: 1px solid transparent;
      padding: ${theme.newTheme.spacing(1)};
      &:active {
        color: ${theme.newTheme.color.primary.light};
      }
      &:disabled {
        color: ${theme.newTheme.color.background.disabled};
        text-decoration: none;
      }
    `,
    gradient: css`
      color: ${theme.newTheme.color.white};
      background: ${theme.newTheme.color.gradient.background.primary.main};
      border: 1px solid transparent;
      transition: ${theme.newTheme.transition.main};

      &:hover {
        background: ${theme.newTheme.color.gradient.background.primary.hover};
        transition: ${theme.newTheme.transition.main};
      }
      &:active {
        background: ${theme.newTheme.color.gradient.background.primary.active};
        transition: ${theme.newTheme.transition.main};
      }
      &:disabled {
        border: 1px solid ${theme.newTheme.color.background.disabled};
        background-color: ${theme.newTheme.color.background.disabled};
        color: ${theme.newTheme.color.grey.main};
      }

      @media only screen and (${theme.newTheme.breakpoints.tablet}) {
        &:hover,
        &:active {
          background: ${theme.newTheme.color.gradient.background.primary.active};
        }
      }

      @media only screen and (${theme.newTheme.breakpoints.mobile}) {
        &:hover,
        &:active {
          background: ${theme.newTheme.color.gradient.background.primary.active};
        }
      }
    `,
  }[variant]);

const ButtonContainer = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none !important;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  color: ${({ theme }) => theme.newTheme.color.white};
  ${({ theme }) => `padding: ${theme.newTheme.spacing(3)} ${theme.newTheme.spacing(6)}`};
  border-radius: ${({ theme }) => theme.newTheme.border.radius.button};
  font-size: ${({ theme }) => theme.newTheme.font.text2.size};
  line-height: ${({ theme }) => theme.newTheme.font.text2.lineHeight};
  ${({ theme, color }) => colorStyles(theme, color)};
  ${({ theme, variant }) => variantStyles(theme, variant)};
  transition: ${({ theme }) => theme.newTheme.transition.main};

  &:hover,
  &:active {
    transition: ${({ theme }) => theme.newTheme.transition.main};
  }

  &:disabled {
    cursor: inherit;
  }

  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    ${({ theme }) => `padding: ${theme.newTheme.spacing(3)} ${theme.newTheme.spacing(6)}`};
    font-size: ${({ theme }) => theme.newTheme.font.text3.size};
    line-height: ${({ theme }) => theme.newTheme.font.text3.lineHeight};
  }
`;

export default ButtonContainer;
