import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`

  html {
    box-sizing: border-box;
    font-size: 18px;
    scroll-padding-top: 90px;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  * {
      font-family: 'Open Sans', sans-serif;
      font-weight: 300;
      color: #3A3A38;
    }

  body, html {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
  }
body * {
  -webkit-font-smoothing: antialiased;
}
  ol, ul {
    list-style: none;
  }

  img {
    max-width: 100%;
    height: auto;
  }
  a{
    text-decoration: none;
  }
`;

export default GlobalStyle;
