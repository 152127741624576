const theme = {
  colors: {
    primary: '#5D73F5',
    primaryLight: '#96a1ff',
    primaryDark: '#0057B2',
    secondary: '#9D46E3',
    secondaryLight: '#C632C6',
    error: '#B00020',
    white: '#FFFFFF',
    grey: '#4A4A4A',
    lightGrey: '#DBDEE1',
    black: '#000000',
    divider: '#E0E0E0',
    hoverButton: 'rgba(0, 125, 255, 0.08)',
    inputForm: '#ebedf5',
    footerText: '#b3b1b1',
    icon: {
      light: '#C2C2C2',
      dark: 'rgba(0, 0, 0, 0.54)',
    },
    translucent: 'rgba(255, 255, 255, 0.97)',
    backgroundLight: '#F5F6FF',
    darkBlue: '#374DCF',
    indigo: '#130472',
    purpleLight: '#9D46E3',
    purpleBackground: '#F0EEFF',
    primaryText: '#616162',
    buttonGradientHover: '#F1F1F1',
    subtitle: '#95959B',
    primaryDarkText: '#311AB5',
  },
  gradients: {
    main: 'linear-gradient(91.98deg, #9D46E3 -1.02%, #C632C6 104.59%)',
    mainLight: 'linear-gradient(91.98deg, #d277ff -1.02%, #fc6af9 104.59%)',
    mainDark: 'linear-gradient(91.98deg, #690cb0 -1.02%, #920095 104.59%)',
    heading: 'linear-gradient(90deg, #7562f3 0%, #db56ab 103.3%)',
    background: 'linear-gradient(87.49deg, #6f64f5 0.6%, #ce49cf 48.69%, #f46f66 98.83%)',
    text: 'linear-gradient(to right, #f5726a 0%, #5d73f5 51%, #f5726a 100%)',
    textCareers: 'linear-gradient(90deg, #25bfea 0%, #498ef1 103.3%)',
    aboutUs: 'linear-gradient(90deg, #c0c7ff 0%, #76ffe0 103.3%)',
    mainButton: 'linear-gradient(91.98deg, #6f64f5 -1.02%, #ce49cf 104.59%)',
    mainButtonHover: 'linear-gradient(87.49deg, #2912BD 0%, #C53791 100%)',
    mainButtonActive: 'linear-gradient(87.49deg, #130472 0%, #7C004F 100%)',
    purpleLight: 'linear-gradient(90deg, #6F64F5 40%, #9D46E3B1 100%)',
  },

  fonts: {
    family: {
      primary: 'Inter, sans-serif',
    },
    colors: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: '#615E5E',
      tertiary: '#605E5E',
      disabled: 'rgba(0, 0, 0, 0.38)',
    },
    sizes: {
      primary: '1rem', // p - 16px
      secondary: '0.875rem', // p - 14px
      icon: '2.3em', // 40px
      listTitle: '1.2em', // 20px
      heading25: '1.5625em', // 25px
      heading30: '1.875em', // 30px
      heading50: '2.5em', // 50px
      heading23: '1.4em',
      secondary20: '1.125em', // 20px
      heading40: '2.5em', // 40px
      headings: {
        heading1: '6rem', // h1 - 96px
        heading2: '3.75rem', // h2 - 60px
        heading3: '3rem', // h3 - 48px
        heading4: '2.125rem', // h4 - 34px
        heading5: '1.5rem', // h5 - 24px
        heading6: '1.25rem', // h6 - 20px
      },
      extraSmall: '14px',
      small: '16px',
      medium: '20px',
      extraMedium: '40px',
      big: '50px',
      extraBig: '64px',
    },
    weights: {
      lighter: '100', // paragraphs
      light: '300',
      normal: '400', // paragraphs
      normalCondensed: '500',
      heavy: '600',
      bold: '700', // headings
      boldCondensed: '800', // headings
    },
  },
  spacing: {
    smallest: '0.3125em', // 5px
    small: '0.5em', // 8px
    smallXL: '0.75em', // 12px
    medium: '1em', // 16px
    spacing18: '1.125em', // 18px
    large: '1.5em', // 24px
    largeM: '1.875', // 30px
    largeXL: '2em', // 32px
    largest: '2.5em', // 40px
    longSM: '3.125em', // 50px
    long: '4em', // 64px
    longest: '6.5em', // 104px
    spacing80: '5em', // 80px
    spacing10: '0.625em', // 10px
    spacing14: '0.875em', // 14px
    spacing20: '1.125em', // 20px
    spacing23: '1.4375em', // 23px
    spacing25: '1.5625em', // 25px
    spacing30: '1.875em', // 30px
    spacing35: '2.1875em', // 35px
    spacing40: '2.5em', // 40px
    spacing60: '3.75em', // 60px
    spacing90: '5.625em', // 90px
    spacing110: '6.25em', // 100px
    spacing120: '7.5em', // 120px
    spacing160: '10em', // 160px
    spacing190: '12em', // 190px
    spacing220: '13.68em', // 220px
  },
  transition: 'all 0.2s ease-in-out',
  opacity: {
    primary: 0.8,
    secondary: 0.5,
  },
  lineHeight: {
    primary: '1.3em',
    secondary: '1.5em',
    medium: '2em',
    large: '2.1875em', // 35px
  },
  borderRadius: '999px',
  radius: {
    main: '0.3125em',
    secondary: '0.938em',
    small: '0.6em',
    full: '50%',
  },
  shadows: {
    boxDefault:
      '0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 10px 20px rgba(0, 0, 0, 0.04);',
    buttonPrimary:
      '0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(76, 81, 191, 0.12), 0px 10px 20px rgba(76, 81, 191, 0.18);',
    navDefault:
      '0px 0px 1px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px -4px 8px rgba(0, 0, 0, 0.04);',
    medium:
      '0px 0px 10px rgba(100, 0, 0, 0), 0px 1px 10px rgba(0, 70, 191, 0.12), 0px 3px 5px rgba(1, 1, 11, 0.1);',
  },
  breakpoints: {
    smallMobile: 'max-width: 479px',
    mobile: 'max-width: 767px',
    tablet: 'max-width: 991px',
    tabletLandscape: 'max-width: 1199px',
    desktop: 'max-width: 1599px',
  },

  // new theme

  newTheme: {
    color: {
      primary: {
        main: '#5D73F5',
        light: '#96A1FF',
        dark: '#0057B2',
        active: '#002F82',
      },
      secondary: {
        main: '#9D46E3',
        light: '#D277FF',
        dark: '#690CB0',
        active: '#33007F',
      },
      grey: {
        main: '#616162',
        lightest: '#bdbdbd',
        light: '#8E8E8F',
        dark: '#373738',
      },
      error: '#F46F66',
      success: '#82E9DE',
      divider: '#E0E0E0',
      disabled: '#8E8E8F',
      black: '#373738',
      pitchBlack: '#000',
      blogBold: '#3f3f3f',
      amethystViolet:{
        main: '#8060EF',
        light: '#7F67F2',
      } ,
      white: '#FFFFFF',
      overlay: '#00000069',
      jobChip: '#8294FF',
      background: {
        grey: '#F1F1F1',
        disabled: '#E0E0E0',
        darkBlue: '#374DCF',
        indigo: '#130472',
        paleLightBlue: '#F5F6FF',
      },
      gradient: {
        background: {
          primary: {
            main: 'linear-gradient(91.98deg, #6F64F5 -1.02%, #CE49CF 104.59%)',
            hover: 'linear-gradient(91.98deg, #3139C1 -1.02%, #9A009D 104.59%)',
            active: 'linear-gradient(91.98deg, #00128F -1.02%, #67006E 104.59%)',
          },
          secondary: {
            main: 'linear-gradient(91.98deg, #25BFEA -1.02%, #498EF1 104.59%)',
            hover: 'linear-gradient(91.98deg, #008FB8 -1.02%, #0061BE 104.59%)',
            active: 'linear-gradient(91.98deg, #006188 -1.02%, #00388D 104.59%)',
          },
          tertiary: {
            main: 'linear-gradient(91.98deg, #16FFF1 -1.02%, #E4D723 104.59%)',
          },
          home: 'linear-gradient(to top right, #00128F 0%, #3139C1 51%, #6F64F5 100%)',
        },
        text: 'linear-gradient(to right, #6F64F5 0%, #CE49CF 51%, #F46F66 100%)',
      },
      service: {
        teamAugmentation: {
          main: '#9D46E3',
          light: '#D277FF',
          dark: '#690CB0',
          background: '#F0EEFF',
        },
        mvpDevelopment: {
          main: '#F06292',
          light: '#ff94c2',
          dark: '#ba2d65',
          background: '#FFECF2',
        },
        consultancy: {
          main: '#039BE5',
          light: '#82E9DE',
          dark: '#00867D',
          background: '#E1F5FE',
        },
      },
      tag: {
        all: '#616162',
        mobile: '#F06292',
        webTechnologies: '#F06292',
        enterprise: '#7C4DFF',
        clientStories: '#00B0FF',
        smb: '#536DFE',
        agile: '#4DB6AC',
        backend: '#536DFE',
        frontend: '#00B0FF',
        methodologies: '#4DB6AC',
        news: '#FFA000',
        devops: '#7C4DFF',
        ourWork: '#304BE2',
        guide: '#6f4ef7',
        default: '#6f4ef7',
      },
    },
    font: {
      family: "'Open Sans', 'sans-serif'",
      title1: {
        size: '3.333rem', // 60px
        lineHeight: '3.889rem', // 70px
      },
      title2: {
        size: '2.778rem', // 50px
        lineHeight: '3.333rem', // 60px
      },
      title3: {
        size: '2.222rem', // 40px
        lineHeight: '2.778rem', // 50px
      },
      title4: {
        size: '1.944rem', // 35px
        lineHeight: '2.5rem', // 45px
      },
      title5: {
        size: '1.667rem', // 30px
        lineHeight: '2.222rem', // 40px
      },
      title6: {
        size: '1.389rem', // 25px
        lineHeight: '1.944rem', // 35px
      },
      text1: {
        size: '1.111rem', // 20px
        lineHeight: '1.667rem', // 30px
      },
      text2: {
        size: '1rem', // 18px
        lineHeight: '1.556rem', // 28px
      },
      text3: {
        size: '0.889rem', // 16px
        lineHeight: '1.444rem', // 26px
      },
      text4: {
        size: '0.778rem', // 14px
        lineHeight: '1.111rem', // 20px
      },
      weight: {
        light: 300,
        regular: 400,
        medium: 500,
        semiBold: 600,
        bold: 700,
      },
    },
    spacing: unity => `${0.222 * unity}rem`,
    opacity: {
      primary: 0.8,
      secondary: 0.5,
    },
    shadow: {
      main: ` 
              0px 0px 1px rgba(0, 0, 0, 0.04), 
              0px 2px 6px rgba(76, 81, 191, 0.12), 
              0px 10px 20px rgba(76, 81, 191, 0.18)`,
    },
    transition: {
      main: '0.1s ease-in-out',
    },
    border: {
      radius: {
        small: '5px',
        medium: '15px',
        main: '8px',
        button: '999px',
        full: '50%',
      },
    },
    breakpoints: {
      smallMobile: 'max-width: 479px',
      mobile: 'max-width: 767px',
      tablet: 'max-width: 991px',
      tabletLandscape: 'max-width: 1199px',
      desktop: 'max-width: 1599px',
    },
  },
};

export default theme;
