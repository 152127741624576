import { useStaticQuery, graphql } from "gatsby";

export const useNavList = () => {
  const data = useStaticQuery(graphql`
    query AllContentfulMenuItem {
      allContentfulMenuItem {
        edges {
          node {
            copy
            url
            page {
              url
            }
            featured
            main
            nestedMenuItems {
              copy
              nestedMenuItems {
                copy
                url
                page {
                  url
                }
                icon {
                  file {
                    url
                  }
                }
              }
              url
              page {
                url
              }
            }
            order
          }
        }
      }
    }
  `);

  const isServices = (item) => item.node.copy === "Services"
  
  const getUrl = (item) => {
    const slug = item.node.url ? item.node.url : item.node.page?.url;
    return slug ? `/${slug}` : '#';
  };

  const handleDropdownList = (item, items) => {
    if (isServices(item)) {
      const categories = item.node.nestedMenuItems;
      const dropdownList = categories.map((category) => {
        const categoryMenuItem = items.find((menuItem) => menuItem.node.copy === category.copy);
        const categoryItems = categoryMenuItem.node.nestedMenuItems.map((categoryItem, i) => {
          const itemMenuItem = items.find((menuItem) => menuItem.node.copy === categoryItem.copy);
          return {
            id: i,
            label: itemMenuItem.node.copy,
            to: getUrl(itemMenuItem),
          };
        });
        return ({
          label: category.copy,
          links: categoryItems,
        })
      });
      return dropdownList;
    }
    else {
      const dropdownLinks = item.node.nestedMenuItems.map((item, i) => {
        const itemMenuItem = items.find((menuItem) => menuItem.node.copy === item.copy);
        return {
          id: i,
          label: itemMenuItem.node.copy,
          to: getUrl(itemMenuItem),
        };
      });
      const dropdownList = [{
        links: dropdownLinks,
      }]
      return dropdownList;
    }
  };

  const items = data.allContentfulMenuItem.edges;
  const sortedMainItems = items.
  filter((item) => item.node.main).
  sort((a, b) => a.node.order - b.node.order);

  const navList = sortedMainItems.map((item) => {
    const dropdownList = item.node.nestedMenuItems
    ? handleDropdownList(item, items)
    : [];

    return ({
      to: getUrl(item),
      label: item.node.copy,
      isDropdown: dropdownList.length > 0,
      isButton: item.node.featured,
      dropdownList,
      image: item.node.icon?.file?.url ? item.node.icon.file.url : null,
    });
  });
  return navList;
};
