import React from 'react';
import PropTypes from 'prop-types';

import { IconContainer, IconLine } from './styles';

const AccordionButtonIcon = ({ openClose, onClick }) => {
  return (
    <IconContainer onClick={onClick}>
      <IconLine />
      <IconLine openClose={openClose && openClose} />
    </IconContainer>
  );
};

AccordionButtonIcon.propTypes = {
  openClose: PropTypes.bool.isRequired,
};

export default AccordionButtonIcon;
