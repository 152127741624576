import React from 'react';
import PropTypes from 'prop-types';

import Container from './styles';

const MainContainer = ({ children, size }) => {
  return <Container size={size}>{children}</Container>;
};

MainContainer.propTypes = {
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'fluid']),
  children: PropTypes.node.isRequired,
};

MainContainer.defaultProps = {
  size: 'sm',
};

export default MainContainer;
