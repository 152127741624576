import styled from 'styled-components';

export const Container = styled.div`
  padding-bottom: ${({ theme }) => theme.spacing.large};

  &:focus-within label {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const Input = styled.input`
  width: 100%;
  overflow: hidden;
  margin: 0;
  color: ${({ theme }) => theme.fonts.colors.primary};
  padding-left: ${({ theme }) => theme.spacing.medium};
  border-radius: ${({ theme }) => theme.radius.main};
  font-style: normal;
  font-weight: ${({ theme }) => theme.fonts.weights.normal};
  font-size: ${({ theme }) => theme.fonts.sizes.primary};
  line-height: ${({ theme }) => theme.spacing.longSM};
  text-indent: ${({ theme }) => theme.spacing.medium};
  padding: 0;
  background-color: ${({ theme }) => theme.colors.inputForm};
  border-color: transparent;

  &:focus {
    color: ${({ theme }) => theme.fonts.colors.primary};
    border: 2px solid ${({ theme }) => theme.colors.primary};
    background-color: ${({ theme }) => theme.colors.white};
    outline: none;
  }

  @media only screen and (${({ theme }) => theme.breakpoints.tablet}) {
    line-height: ${({ theme }) => theme.spacing.largest};
  }

  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    line-height: ${({ theme }) => theme.spacing.largest};
  }
`;

export const Label = styled.label`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  margin-bottom: ${({ theme }) => theme.spacing.small};
  font-style: normal;
  font-weight: ${({ theme }) => theme.fonts.weights.heavy};
  font-size: ${({ theme }) => theme.fonts.sizes.primary};
  color: ${({ theme }) => theme.fonts.colors.secondary};
`;
