import styled from 'styled-components';

export const FooterContainer = styled.footer`
  background-color: ${({ theme }) => theme.colors.grey};
  position: relative;
  z-index: 9;
  width: 100%;
  margin: 0;
  padding: 0;

  p,
  a {
    color: ${({ theme }) => theme.colors.footerText};
  }

  hr {
    background: ${({ theme }) => theme.gradients.background};
    width: 100%;
    height: 3px;
  }
`;

export const MainContent = styled.div`
  width: 100%;
  margin: 0;
  padding: ${({ theme }) => theme.spacing.largeXL} 0;
`;

export const FooterHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary};
  padding-bottom: ${({ theme }) => theme.spacing.largeXL};
  margin-bottom: ${({ theme }) => theme.spacing.largeXL};
`;

export const Title = styled.h3`
  color: ${({ theme }) => theme.colors.white};
  font-weight: 500;
  margin: 0;
  font-size: ${({ theme }) => theme.fonts.sizes.headings.heading5};

  @media only screen and (${props => props.theme.breakpoints.tablet}) {
    font-size: ${({ theme }) => theme.fonts.sizes.headings.heading6};
  }
`;

export const Span = styled.span`
  color: ${({ theme }) => theme.colors.white};
  display: block;
`;

export const Copyright = styled.div`
  padding: ${({ theme }) => theme.spacing.medium} 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const Text = styled.p`
  font-size: ${({ theme }) => theme.fonts.sizes.secondary};
  text-align: center;
  margin: 0;
  padding: 0;
`;

export const Bold = styled(Text)`
  font-weight: 600;
  display: inline;
`;

export const List = styled.ul`
  margin: 0;
  padding: 0;

  li {
    padding: ${({ theme }) => theme.spacing.smallXL} 0;
    cursor: pointer;

    &:nth-child(1) {
      padding-top: 0;
    }

    &:nth-child(8) {
      padding-bottom: 0;
    }

    a {
      font-size: ${({ theme }) => theme.fonts.sizes.primary} 0;
      color: ${({ theme }) => theme.colors.lightGrey};

      &:hover {
        opacity: ${({ theme }) => theme.opacity.secondary};
      }

      @media only screen and (${props => props.theme.breakpoints.tablet}) {
        &:hover {
          opacity: 1;
        }
      }
    }

    @media only screen and (${props => props.theme.breakpoints.tablet}) {
      padding: ${({ theme }) => theme.spacing.medium} 0;
    }
  }
`;

export const Offices = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.newTheme.color.grey.lightest};
  p {
    color: ${({ theme }) => theme.newTheme.color.grey.lightest};
    b {
      color: ${({ theme }) => theme.newTheme.color.grey.lightest};
      b {
        color: ${({ theme }) => theme.newTheme.color.grey.lightest};
      }
    }
  }
  @media only screen and (${props => props.theme.breakpoints.mobile}) {
    padding-top: ${({ theme }) => theme.spacing.large};
    div {
      padding: ${({ theme }) => theme.spacing.small} 0;
    }
  }
`;

export const TextGradient = styled.span`
  background: ${({ theme }) => theme.gradients.textCareers};
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-left: 5px;
`;

export const ContainerGrid = styled.div`
  width: 100%;
  padding: 0 !important;
`;

export const SocialContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: ${({ theme }) => theme.spacing.small};

  li {
    margin-bottom: 0;
  }

  @media only screen and (${props => props.theme.breakpoints.tabletLanding}) {
    justify-content: flex-end;

    li {
      margin-bottom: ${({ theme }) => theme.spacing.smallXL};
    }
  }

  @media only screen and (${props => props.theme.breakpoints.tablet}) {
    justify-content: flex-end;
    margin-top: ${({ theme }) => theme.spacing.largest};

    li {
      margin-bottom: ${({ theme }) => theme.spacing.smallXL};
    }
  }

  @media only screen and (${props => props.theme.breakpoints.mobile}) {
    justify-content: flex-start;
  }
`;

export const NewsletterContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;

  @media only screen and (${props => props.theme.breakpoints.mobile}) {
    justify-content: flex-start;
    margin-top: ${({ theme }) => theme.spacing.medium};
    margin-bottom: ${({ theme }) => theme.spacing.large};
  }
  @media only screen and (${props => props.theme.breakpoints.smallMobile}) {
    justify-content: center;
  }
`;
