import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 0.9rem 3rem;
    line-height: 1.25rem;
  }
`;

export const Form = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  div:first-child {
    padding: 0;
    label {
      margin: 0;
    }
    input {
      padding: calc(0.9rem - 2px) 0;
      line-height: 1.25rem;
      width: 17rem;
      border-radius: 0;
      border-top-left-radius: 999px;
      border-bottom-left-radius: 999px;
      @media only screen and (${props => props.theme.breakpoints.tablet}) {
        width: 15rem;
      }
      @media only screen and (${props => props.theme.breakpoints.smallMobile}) {
        width: 100%;
      }
    }
  }
  @media only screen and (${props => props.theme.breakpoints.smallMobile}) {
    flex-direction: column;
    justify-content: center !important;
    gap: 1rem;
    button {
      width: 100%;
      border-radius: 999px;
    }
    div:first-child {
      input {
        width: 100%;
        border-radius: 999px;
      }
    }
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  p {
    position: absolute;
    left: 0;
    bottom: 0;
    position: absolute;
    left: 0.5rem;
    top: calc(100% + 0.5rem);
    margin: 0;
  }
  @media only screen and (${props => props.theme.breakpoints.smallMobile}) {
    width: 100%;
  }
`;

export const SuccessMessage = styled.p`
  color: white !important;
`;
