import styled from 'styled-components';
import GatsbyLink from 'atomic-design/atoms/link';

export const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  margin: 52px 0;
  div {
    span {
      font-style: italic;
      font-weight: normal;
      font-size: 20px;
      line-height: 30px;
      letter-spacing: -0.02em;
      color: #4f4f4f;
    }
  }
`;

export const ContainerQuote = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const TextContainer = styled.div`
  position: relative;
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: fill-content;
`;

export const QuoteText = styled.span`
  flex-grow: 1;
  margin: 0 0 10px 15px;
  font-style: italic;
`;

export const QuoteAuthor = styled.span`
  margin: 0 0 0 15px;
  font-style: italic;
`;

export const FullPlane = styled.div`
  position: relative;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  flex-grow: 1;
`;

export const QuoteDiv = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  margin: 12px 0;
  font-style: italic;
  font-weight: normal;
  font-size: 30px;
  position: relative;
`;

export const Quote = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  position: absolute;
  top: -10px;
  left: -10px;
`;

export const Link = styled(GatsbyLink)``;
