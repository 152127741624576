import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { ChevronArrow } from 'ui/svg';
import {
  Container,
  Content,
  DropdownItem,
  DropdownMenu,
  DropdownContent,
  StyledSection,
  StyledSectionLink,
} from './styles';
import Link from '../../atoms/link';

const Dropdown = ({ children, itemList, isShow, isHome, isFixed }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    isShow && setShow(!show);
    return () => {
      setShow(false);
    };
  }, [isShow]);

  return (
    <Container
      onMouseOver={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
      onFocus={() => setShow(true)}
    >
      <DropdownItem>
        {children}
        <ChevronArrow
          size={20}
          hover={!show}
          ariaLabel={show ? 'open' : 'close'}
          isHome={isHome}
          isFixed={isFixed}
        />
      </DropdownItem>
      <Content
        onMouseOver={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
        onFocus={() => setShow(true)}
      >
        <DropdownMenu show={show && show} fade={show} onMouseLeave={() => setShow(false)}>
          <DropdownContent
            isHome={isHome}
            isFixed={isFixed}
            hasSections={itemList.filter(section => !!section.label).length > 0}
          >
            {itemList.map(section => (
              <>
                {section.label && <StyledSection variant="caption">{section.label}</StyledSection>}
                {section.links.map(item => (
                  <>
                    {section.label ? (
                      <StyledSectionLink to={`${item.to}`} key={item.id}>
                        <li>{item.label}</li>
                      </StyledSectionLink>
                    ) : (
                      <Link to={`${item.to}`} key={item.id}>
                        <li>{item.label}</li>
                      </Link>
                    )}
                  </>
                ))}
              </>
            ))}
          </DropdownContent>
        </DropdownMenu>
      </Content>
    </Container>
  );
};

Dropdown.propTypes = {
  children: PropTypes.node.isRequired,
  itemList: PropTypes.array.isRequired,
  isHome: PropTypes.bool,
  isShow: PropTypes.bool,
  isFixed: PropTypes.bool,
};

Dropdown.defaultProp = {
  isHome: false,
  isShow: false,
  isFixed: false,
};

export default Dropdown;
