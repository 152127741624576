import React from 'react';
import PropTypes from 'prop-types';

import { Helmet } from 'react-helmet';

const MetaTag = props => {
  const { title, htmlTitleTag, description, image, url, viewport, type, charSet } = props;

  const isCareersPage = url?.includes('/careers');

  return (
    <Helmet title={htmlTitleTag || title}>
      {charSet && <meta charSet={charSet} />}

      {description && <meta name="description" content={description} />}
      {viewport && <meta name="viewport" content={viewport} />}

      {description && <meta itemProp="description" content={description} />}
      {image && <meta itemProp="image" content={image} />}

      {isCareersPage && <meta name="robots" content="noindex" />}

      {url && <meta property="og:url" content={url} />}
      {url && <meta property="og:image:secure_url" content={url} />}
      {type && <meta property="og:type" content={type} />}
      {title && <meta property="og:title" content={title} />}
      {description && <meta property="og:description" content={description} />}
      {image && <meta property="og:image" content={image} />}
      {image && <meta property="og:image:type" content="image/jpeg" />}
      {image && <meta property="og:image:width" content="400" />}
      {image && <meta property="og:image:height" content="300" />}
      {image && <meta name="twitter:card" content={image} />}
      {title && <meta name="twitter:title" content={title} />}
      {description && <meta name="twitter:description" content={description} />}
      {image && <meta name="twitter:image" content={image} />}
    </Helmet>
  );
};

MetaTag.propTypes = {
  title: PropTypes.bool,
  description: PropTypes.string,
  image: PropTypes.string,
  url: PropTypes.string,
  viewport: PropTypes.string,
  type: PropTypes.string,
  charSet: PropTypes.string,
  htmlTitleTag: PropTypes.string,
};

MetaTag.defaultProps = {
  htmlTitleTag: '',
  title: false,
  description: '',
  image: '',
  url: '',
  viewport: '',
  type: '',
  charSet: '',
};

export default MetaTag;
