import styled from 'styled-components';

const ScrollButton = styled.button`
  width: ${({ theme }) => theme.newTheme.spacing(10)};
  height: ${({ theme }) => theme.newTheme.spacing(10)};
  background-color: ${({ theme }) => theme.newTheme.color.white};
  box-shadow: ${({ theme }) => theme.newTheme.shadow.main};
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.newTheme.spacing(2)};
  position: fixed;
  z-index: 10;
  bottom: 0;
  cursor: pointer;
  transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  right: 0;
  margin: ${({ theme }) => theme.newTheme.spacing(5)};
  border-radius: ${({ theme }) => theme.newTheme.border.radius.full};
  animation: ${({ isShowing }) =>
    isShowing ? 'fadeIn .6s ease-in-out;' : 'fadeOut .6s ease-in-out;'};
  -webkit-overflow-scrolling: touch;

  svg {
    transform: rotate(90deg);
    width: ${({ theme }) => theme.newTheme.spacing(5)};
    height: ${({ theme }) => theme.newTheme.spacing(5)};
  }
  &:hover {
    transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    -webkit-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    opacity: 0.8;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
`;

export default ScrollButton;
