import styled, { css } from 'styled-components';
import { fadeIn } from 'ui/animations';

const baseContainer = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  overflow: initial;
`;

const Container = styled.div`
  ${baseContainer}
  padding-top: ${({ isHome, theme }) => (isHome ? '0' : theme.newTheme.spacing(18))};
  animation: ${fadeIn} 0.2s;
`;

export const MainContainer = styled.main`
  ${baseContainer}
`;

export default Container;
