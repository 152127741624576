import { useEffect, useState, useCallback } from 'react';

/**
 * @param {'mobile'|'tablet'} media breakpoint to match
 *
 * @return {boolean} returns true when the viewport matches the provided breakpoint
 */
const breakpoints = {
  smallMobile: 479,
  mobile: 767,
  tablet: 991,
};

const isMobileView = media => {
  if (typeof window == 'undefined') {
    return null;
  }

  return window.innerWidth <= breakpoints[media];
};

const useIsMobile = (media = 'mobile') => {
  const [isMobile, setIsMobile] = useState(null);

  const updateIsMobile = useCallback(() => {
    setIsMobile(isMobileView(media));
  }, [media]);

  useEffect(updateIsMobile, [media]);

  useEffect(() => {
    window.addEventListener('resize', updateIsMobile);
    return () => {
      window.removeEventListener('resize', updateIsMobile);
    };
  }, [updateIsMobile]);

  return isMobile;
};

export default useIsMobile;
