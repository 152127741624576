import styled, { css } from 'styled-components';
import Link from 'atomic-design/atoms/link';

const sizeStyles = (theme, size) =>
  ({
    normal: css`
      padding: ${theme.spacing.largeXL} 0;
      background-color: transparent;

      @media only screen and (${theme.breakpoints.tablet}) {
        padding: ${theme.spacing.large} 0;
      }

      @media only screen and (${theme.breakpoints.mobile}) {
        padding: ${theme.spacing.small} 0;
      }
    `,
    change: css`
      padding: ${theme.spacing.smallXL} 0;
      transition: ${theme.transition};
      background-color: ${theme.colors.translucent};
      box-shadow: ${theme.shadows.boxDefault};

      a {
        &:hover {
          color: ${theme.colors.primary};
        }
      }

      button {
        background: ${theme.gradients.mainButton};

        @media only screen and (${theme.breakpoints.tablet}) {
          background: ${theme.colors.white};
        }

        @media only screen and (${theme.breakpoints.mobile}) {
          background: ${theme.colors.white};
        }
      }

      @media only screen and (${theme.breakpoints.tablet}) {
        padding: ${theme.spacing.small} 0;
      }
    `,
  }[(theme, size)]);

export const Header = styled.header`
  ${({ theme, resize }) => (resize ? sizeStyles(theme, 'change') : sizeStyles(theme, 'normal'))};
  width: 100%;
  margin: 0;
  position: fixed;
  z-index: 999;
  top: 0;
  ${({ theme, inverse }) =>
    inverse &&
    css`
      background-color: ${theme.colors.white};
      box-shadow: ${theme.shadows.boxDefault};
    `}
`;

export const Content = styled.div`
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    ${({ theme }) => `padding: ${theme.spacing.smallXL} ${theme.spacing.large}`};
    border: 1px solid ${({ theme }) => theme.colors.white};
    ${({ theme, isHome }) =>
      isHome
        ? `background: transparent; border: 1px solid ${theme.colors.white} !important;`
        : `background: ${theme.gradients.mainButton}; border: 1px solid transparent !important;`};

    ${({ theme, isHome, isFixed }) =>
      isHome && isFixed && `background: ${theme.gradients.mainButton}`};

      @media only screen and (${({ theme }) => theme.breakpoints.tablet}) {
        background: ${({ theme }) => `${theme.colors.white}`};
      }

      @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
        background: ${({ theme }) => `${theme.colors.white}`};
      }

      &:hover {
        ${({ theme }) => `border: 1px solid ${theme.colors.white}`};

        ${({ theme, isHome, isFixed }) =>
          isHome &&
          !isFixed &&
          ` 
          background: ${theme.gradients.text};
          font-weight: 500;
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          transition: ${theme.transition};
          
          &:after {
            content: '';
            border-radius: 9999px;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            z-index: -1;
            background: ${theme.colors.white};
          };

          @media only screen and (${theme.breakpoints.tablet}) {
            &:after {
              background: transparent;
            };
          };
    
          @media only screen and (${theme.breakpoints.mobile}) {
            &:after {
            background: transparent;
            };
          };
        `};
      }
    }
  }

  @media only screen and (${({ theme }) => theme.breakpoints.tablet}) {
    justify-content: space-between;

    nav {
      display: none;
    }

    a {
      align-content: flex-end;
    }

    button {
      border: none !important;
      padding: 0;
    }
  }
`;

export const NavContainer = styled.div``;

export const NavBrand = styled(Link)`
  display: flex;
  align-items: center;

  @media only screen and (${({ theme }) => theme.breakpoints.tablet}) {
    svg {
      width: ${({ theme }) => theme.fonts.sizes.headings.heading1};
      transform: translateY(2px);
    }
  }
`;

export const BurgerContainer = styled.span`
  cursor: pointer;
  display: none;
  margin: 0;
  position: relative;
  z-index: 0;

  button {
    background-color: transparent;
    transform: translateY(3px);
  }

  @media only screen and (${({ theme }) => theme.breakpoints.tablet}) {
    display: block;
    padding: 0;
    position: relative;
    z-index: 9;
  }
`;

export const NavbarItemDetail = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: fit-content;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const BackdropFilter = styled.div`
  content: '';
  left: 0;
  bottom: 0;
  backdrop-filter: blur(2px);
  background: transparent;
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 10;
  transition: ${({ theme }) => theme.transition};
  transform: translateY(0%);
`;

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15.5%;
`;

export const StyledImage = styled.img`
  overflow: visible;
  width: 100%;
  object-fit: cover;
  object-position: center;
`;

export const NavbarItemDetailTitle = styled.p`
  display: flex;
  align-items: center;
  white-space: nowrap;
  gap: ${({ theme }) => theme.spacing.small};
  color: ${({ theme }) => theme.colors.primaryDarkText};
  margin: 0;
  width: 250px;
`;

export const NavbarItemDetailLinks = styled.div`
  display: grid;
  grid-auto-flow: ${({ row }) => (row ? 'column' : 'row')};
  grid-template-rows: 1fr;
  align-items: flex-start;
  justify-content: flex-start;
  gap: ${({ theme, row }) => (row ? theme.spacing.large : theme.spacing.smallXL)};
`;

export const LinkItem = styled(Link)`
  font-style: normal;
  font-weight: 500;
  font-size: ${({ theme }) => theme.fonts.sizes.primary};
  text-decoration: none;
  &:hover,
  &:hover p {
    color: ${({ theme }) => theme.colors.primary};
  }
  &:hover p svg path {
    stroke: ${({ theme }) => theme.colors.primary};
  }
`;

export const NavbarItemDetailContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${({ row }) => (row ? 'center' : 'flex-start')};
  gap: ${({ theme }) => theme.spacing.medium};
  padding: ${({ theme }) => theme.spacing.medium};
  flex-basis: 250px;
`;

export const NavbarContentWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.largest};
  padding: ${({ theme }) => theme.spacing.medium};
  flex: 1;
  margin: 0 ${({ theme }) => theme.spacing.largest};
`;
