import React, { useEffect, useState } from 'react';
import StyledReadingProgressBar from './styles';

/**
 * Horizontal progress bar fixed at the top, which increases in size depending on scroll height
 * @param {React.Ref} target the container ref which will be used to track scroll height
 * @returns {React.Component} a Styled Reading Progress Bar
 */
const ReadingProgress = ({ target }) => {
  const [readingProgress, setReadingProgress] = useState(0);

  const scrollListener = () => {
    if (!target?.current) {
      return;
    }

    const element = target.current;
    const totalHeight = element.clientHeight - element.offsetTop - window.innerHeight;
    const windowScrollTop =
      window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    if (windowScrollTop === 0) {
      return setReadingProgress(0);
    }

    if (windowScrollTop > totalHeight) {
      return setReadingProgress(100);
    }

    setReadingProgress((windowScrollTop / totalHeight) * 100);
  };

  useEffect(() => {
    window.addEventListener('scroll', scrollListener);
    return () => window.removeEventListener('scroll', scrollListener);
  });

  return <StyledReadingProgressBar style={{ width: `${readingProgress}%` }} />;
};

export default ReadingProgress;
