import React, { useState } from 'react';
import FormInput from 'atomic-design/organisms/contact-form/form-input';
import Button from 'atomic-design/atoms/button';
import { API } from 'aws-amplify';
import { ButtonWrapper, Form, FormContainer, SuccessMessage } from './styles';

const Newsletter = () => {
  const [emailAddress, setEmailAddress] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [subMessage, setSubMessage] = useState('');

  const sendGTMEvent = (err = false) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: `web_newsletter_${err ? 'error' : 'sub'}`,
      [`sub_email${err ? '_error' : ''}`]: emailAddress,
    });
  };

  const handleNewsletterSubmit = () => {
    setSubMessage('Subscribing...');
    if (emailAddress === '') {
      setSubMessage('Email address cannot be empty');
      return;
    }
    API.post('newsletterApi', '/newsletter-subscribe', {
      body: { emailAddress },
    })
      .then(res => {
        setSuccessMessage('Great! Keep an 👀 on your inbox.');
        sendGTMEvent();
      })
      .catch(error => {
        sendGTMEvent(true);
        if (error.response) {
          switch (error.response.status) {
            case 409:
              setSubMessage('Email already in use, try a new one');
              break;
            case 400:
              setSubMessage('Invalid email address');
              break;
            default:
              setSubMessage('Subscription error');
              break;
          }
        } else {
          setSubMessage('Subscription error');
        }
      });
  };

  return (
    <>
      {successMessage !== '' ? (
        <SuccessMessage>{successMessage}</SuccessMessage>
      ) : (
        <FormContainer>
          <Form>
            <FormInput
              type="email"
              name="emailAddress"
              value={emailAddress}
              onChange={e => {
                setEmailAddress(e.target.value);
              }}
              required
              placeholder="Your best email"
            />
            <ButtonWrapper>
              <Button onClick={handleNewsletterSubmit}>Subscribe</Button>
            </ButtonWrapper>
          </Form>
          <p>{subMessage}</p>
        </FormContainer>
      )}
    </>
  );
};

export default Newsletter;
