import React from 'react';
import PropTypes from 'prop-types';

import { OfficeContainer, Country, Address } from './styles';

const Office = ({ country, address }) => {
  return (
    <OfficeContainer>
      <Country>{country}</Country>
      <Address>{address}</Address>
    </OfficeContainer>
  );
};

Office.propTypes = {
  country: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
};

export default Office;
