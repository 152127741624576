import styled from 'styled-components';
import Link from 'atomic-design/atoms/link';

export const Container = styled.div`
  margin-top: 5px;
`;

export const Content = styled.div`
  width: 100%;
  &:after {
    content: '.';
    visibility: collapse;
  }
`;

export const DropdownItem = styled.span`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transform: translateY(10px);

  svg {
    transform: translateY(2px);
    margin-left: ${({ theme }) => theme.spacing.smallest};

    @media only screen and (${({ theme }) => theme.breakpoints.tablet}) {
      display: none;
    }
  }

  @media only screen and (${({ theme }) => theme.breakpoints.tablet}) {
    justify-content: space-between;
    transform: translateY(0);

    button div {
      position: absolute;
      z-index: 9999;
      margin-right: ${({ theme }) => theme.spacing.medium};
    }

    &:hover {
      pointer-events: none !important;
    }
  }
`;

export const DropdownMenu = styled.div`
  position: absolute;
  display: none;
  ${({ show }) => (show ? `height: auto; display: inherit;` : 'height: 0')};
  animation: ${({ fade }) => (fade ? 'fadeIn .1s linear' : 'fadeOut .1s linear')};
  cursor: initial;
  padding-left: 0;
  z-index: 999;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.spacing.smallest};
  overflow: hidden;
  box-shadow: ${({ theme }) => theme.shadows.medium};
  margin-top: 20px;

  @media only screen and (${({ theme }) => theme.breakpoints.tablet}) {
    width: 85%;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 0;
    }
  }
`;

export const DropdownContent = styled.ul`
  color: ${({ theme }) => theme.fonts.colors.primary};

  padding: ${({ hasSections }) => (hasSections ? '11.5px 0' : '0')};
  margin: 0;
  list-style: none;
  text-decoration: none;

  li {
    font-size: ${({ theme }) => theme.fonts.sizes.secondary};
    padding: ${({ theme }) => theme.spacing.large} ${({ theme }) => theme.spacing.large};

    &:hover {
      background-color: ${({ theme }) => theme.colors.backgroundLight};
    }

    @media only screen and (${({ theme }) => theme.breakpoints.tablet}) {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      padding: ${({ theme }) => theme.spacing.large} ${({ theme }) => theme.spacing.large} !important;

      &:hover {
        background-color: ${({ theme }) => theme.colors.white} !important;
      }
    }
  }

  @media only screen and (${({ theme }) => theme.breakpoints.tablet}) {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
`;

export const StyledSection = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.primary};
  padding: 7.5px 21px;
`;

export const StyledSectionLink = styled(Link)`
  li {
    padding: 7.5px 21px;
    font-size: 14px;
  }
`;
