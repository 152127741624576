import React from 'react';

import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import Link from 'atomic-design/atoms/link';
import { Container, Row, Col } from 'react-grid-system';
import Office from 'atomic-design/organisms/office';
import SocialNetworkList from 'atomic-design/atoms/social-network-list';
import ListDivider from 'atomic-design/atoms/list-divider';
import MainContainer from 'atomic-design/atoms/main-container';
import iconList from 'domain/socialMediaIcons';
import theme from 'styles/theme';
import {
  Title,
  Copyright,
  Offices,
  Text,
  Bold,
  MainContent,
  FooterContainer,
  FooterHeader,
  Span,
  ContainerGrid,
  List,
  SocialContainer,
  NewsletterContainer,
  TextGradient,
} from './styles';
import Newsletter from '../../atoms/newsletter';

const Footer = ({ navList }) => {
  const data = useStaticQuery(graphql`
    query {
      contentfulGeneralSettings {
        childContentfulGeneralSettingsContactPageJsonNode {
          items {
            title
            address
            office
          }
        }
      }
    }
  `);

  const offices =
    data.contentfulGeneralSettings.childContentfulGeneralSettingsContactPageJsonNode.items;

  const args = {
    size: theme.fonts.sizes.icon,
    color: theme.colors.icon.light,
  };

  const icons = iconList({ ...args });

  return (
    <FooterContainer role="contentinfo">
      <MainContent>
        <MainContainer>
          <FooterHeader>
            <Container fluid component={ContainerGrid}>
              <Row>
                <Col xs={12} sm={12} md={6} lg={6}>
                  <Title>
                    Get a FREE dose of Tech news
                    <Span>from your friendly sidekick, NaNLABS ;)</Span>
                  </Title>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6}>
                  <NewsletterContainer>
                    <Newsletter />
                  </NewsletterContainer>
                </Col>
              </Row>
            </Container>
          </FooterHeader>
          <Container fluid component={ContainerGrid}>
            <Row>
              <Col xs={12} sm={12} md={6} lg={6}>
                <List>
                  {navList.map(
                    item =>
                      item.to !== '/contact' &&
                      item.to !== '/services' && (
                        <li key={item.label}>
                          <Link to={item.to} rel="noreferrer noopener">
                            {item.label}
                          </Link>
                        </li>
                      )
                  )}
                  <li>
                    <Link to="/careers" rel="noreferrer noopener">
                      Careers
                      <TextGradient>We are hiring!</TextGradient>
                    </Link>
                  </li>
                  <li>
                    <Link to="/quality-policy" rel="noreferrer noopener">
                      Quality Policy
                    </Link>
                  </li>
                </List>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6}>
                <Offices>
                  {offices.map(office => (
                    <Office
                      key={office.title}
                      country={office.title}
                      address={`${office.address}, ${office.office}`}
                    />
                  ))}
                </Offices>
                <SocialContainer>
                  <SocialNetworkList iconList={icons} />
                </SocialContainer>
              </Col>
            </Row>
          </Container>
        </MainContainer>
      </MainContent>
      <ListDivider />
      <MainContainer>
        <Copyright>
          <Text>
            {`${new Date().getFullYear()} © `}
            <Bold>NaN</Bold>
            LABS. All Rights Reserved.
          </Text>
        </Copyright>
      </MainContainer>
    </FooterContainer>
  );
};

Footer.PropTypes = {
  navList: PropTypes.array.isRequired,
};

export default Footer;
