import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { NaNLogo, HamburgerMenu, CloseIcon, NavArrowRight } from 'ui/svg';
import MainContainer from 'atomic-design/atoms/main-container';
import Button from 'atomic-design/atoms/button';
import useScroll from 'hooks/useScroll';
import useIsMobile from 'hooks/useIsMobile';
import NavList from '../nav-list';
import {
  Header,
  NavBrand,
  BurgerContainer,
  Content,
  NavContainer,
  NavbarItemDetail,
  BackdropFilter,
  StyledImage,
  NavbarItemDetailTitle,
  NavbarItemDetailLinks,
  LinkItem,
  NavbarItemDetailContent,
  NavbarContentWrapper,
  ImageWrapper,
} from './styles';

const Navbar = ({
  navList,
  path,
  isHome,
  handleToggle,
  isMenuOpen,
  showMenu = true,
  whiteNaNLogo,
}) => {
  const [selectedView, setSelectedView] = useState(null);
  const isMobile = useIsMobile('tablet');
  const isFixed = useScroll();

  const handleSelectView = view => {
    setSelectedView(view);
  };

  const selectedItem = useMemo(() => {
    if (selectedView) {
      return navList.find(item => item.label === selectedView);
    }
    return null;
  }, [selectedView, navList]);

  const handleEscKey = event => {
    if (event.keyCode === 27 || event.key === 'Escape') {
      handleSelectView(null);
    }
  };

  return (
    <>
      <Header
        resize={!isMenuOpen && isFixed}
        inverse={selectedItem}
        backdrop={!isMobile && selectedItem}
        onKeyDown={handleEscKey}
      >
        <MainContainer>
          <Content isHome={isHome && !selectedItem} isFixed={isFixed}>
            <NavBrand to="/" alt="nanlabs logo" aria-label="nanlabs logo">
              <NaNLogo
                inverse={
                  (isHome && !isFixed && !selectedItem && !isMenuOpen) ||
                  (!selectedItem && !isFixed && whiteNaNLogo)
                }
              />
            </NavBrand>
            <NavContainer>
              {showMenu && (
                <>
                  <NavList
                    navList={navList}
                    path={path}
                    isHome={isHome && !selectedItem}
                    isFixed={isFixed}
                    handleSelectView={handleSelectView}
                    selectedView={selectedView}
                  />
                  <BurgerContainer onClick={handleToggle}>
                    <Button variant="icon" ariaLabel={isMenuOpen ? 'open' : 'close'}>
                      <span alt={isMenuOpen ? 'burger icon' : 'close icon'}>
                        {isMenuOpen ? (
                          <CloseIcon />
                        ) : (
                          <HamburgerMenu inverse={isHome && !isFixed} />
                        )}
                      </span>
                    </Button>
                  </BurgerContainer>
                </>
              )}
            </NavContainer>
          </Content>
          {!isMobile && selectedItem && (
            <NavbarItemDetail onMouseLeave={() => handleSelectView(null)}>
              <NavbarContentWrapper>
                <ImageWrapper>
                  {selectedItem.image && (
                    <StyledImage src={selectedItem.image} alt={selectedItem.label} />
                  )}
                </ImageWrapper>
                {selectedItem.dropdownList?.map((item, index) => (
                  <NavbarItemDetailContent key={index} row={!item.label}>
                    {item.label ? (
                      <>
                        <NavbarItemDetailTitle>
                          {item.label}
                          <NavArrowRight />
                        </NavbarItemDetailTitle>
                        <NavbarItemDetailLinks>
                          {item.links.map(link => (
                            <LinkItem to={link.to} key={link.label}>
                              {link.label}
                            </LinkItem>
                          ))}
                        </NavbarItemDetailLinks>
                      </>
                    ) : (
                      <NavbarItemDetailLinks row>
                        {item.links.map(link => (
                          <LinkItem to={link.to} key={link.label}>
                            <NavbarItemDetailTitle>
                              {link.label}
                              <NavArrowRight />
                            </NavbarItemDetailTitle>
                          </LinkItem>
                        ))}
                      </NavbarItemDetailLinks>
                    )}
                  </NavbarItemDetailContent>
                ))}
              </NavbarContentWrapper>
            </NavbarItemDetail>
          )}
        </MainContainer>
      </Header>
      {!isMobile && selectedItem && <BackdropFilter />}
    </>
  );
};

Navbar.propTypes = {
  navList: PropTypes.array.isRequired,
  path: PropTypes.string.isRequired,
  isHome: PropTypes.string.isRequired,
  handleToggle: PropTypes.func.isRequired,
  isMenuOpen: PropTypes.bool.isRequired,
  showMenu: PropTypes.bool,
  whiteNaNLogo: PropTypes.bool,
};

export default Navbar;
