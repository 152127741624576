import React from 'react';
import PropTypes from 'prop-types';

import NavItem from '../nav-item';
import { Container, NavListStyled } from './styles';

const NavList = ({ navList, path, isHome, isFixed, selectedView, handleSelectView }) => {
  return (
    <Container>
      <NavListStyled isHome={isHome}>
        {navList.map(item => (
          <NavItem
            key={item.label}
            to={item.to}
            label={item.label}
            isActive={path && path.startsWith(item.to)}
            isHome={isHome}
            isFixed={isFixed}
            isDropdown={item.isDropdown}
            dropdownList={item.dropdownList}
            isButton={item.isButton}
            show={selectedView === item.label}
            handleSelectView={handleSelectView}
          />
        ))}
      </NavListStyled>
    </Container>
  );
};

NavList.propTypes = {
  navList: PropTypes.array.isRequired,
  path: PropTypes.string.isRequired,
  isHome: PropTypes.bool.isRequired,
  isFixed: PropTypes.bool.isRequired,
  selectedView: PropTypes.string,
  handleSelectView: PropTypes.func.isRequired,
};

export default NavList;
