import React, { useState } from 'react';
import PropTypes from 'prop-types';

import NavList from '../nav-list';
import { Content, Menu } from './styles';

const MobileMenu = ({ navList, path, isHome, isOpen }) => {
  const [accordionSection, setAccordionSection] = useState(null);

  return (
    <Menu display={isOpen} fade={isOpen}>
      <Content>
        <NavList
          navList={navList}
          path={path}
          isHome={isHome}
          selectedView={accordionSection}
          handleSelectView={setAccordionSection}
        />
      </Content>
    </Menu>
  );
};

MobileMenu.propTypes = {
  navList: PropTypes.array.isRequired,
  path: PropTypes.string.isRequired,
  isHome: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default MobileMenu;
