import styled from 'styled-components';

const StyledReadingProgressBar = styled.div`
  background: #5D73F5;
  position: fixed;
  top: 0;
  left: 0;
  height: 3px;
  z-index: 9999;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
`;

export default StyledReadingProgressBar;
