import React from 'react';
import { LinkedInIcon, GitHubIcon, InstagramIcon } from 'ui/svg';

const iconList = ({ size, color }) => [
  {
    name: 'github',
    to: 'https://github.com/nanlabs',
    icon: <GitHubIcon size={size} color={color} />,
  },
  {
    name: 'instagram',
    to: 'https://www.instagram.com/nanlabs/',
    icon: <InstagramIcon size={size} color={color} />,
  },
  {
    name: 'linkedin',
    to: 'https://www.linkedin.com/company/nan-labs/',
    icon: <LinkedInIcon size={size} color={color} />,
  },
];

export default iconList;
